import { lazy } from 'react';
import { NavBarRouteType, RoutesPaths, RouteType } from './Routes.types';

const Workshops = lazy(() => import('pages/public/Workshop/Workshops'));
const WorkshopById = lazy(() => import('pages/public/Workshop'));
const PageNotFound = lazy(() => import('pages/public/PageNotFound'));
const ShippingKitInfo = lazy(() => import('pages/public/ShippingKitInfo'));
const MeetingLink = lazy(() => import('pages/public/MeetingLink'));

export const appRoutes: RouteType[] = [
  {
    component: Workshops,
    path: RoutesPaths.WORKSHOPS,
    title: 'Workshops',
  },
  {
    component: WorkshopById,
    path: RoutesPaths.WORKSHOPS_BY_ID,
    title: 'Workshop',
  },
  {
    component: PageNotFound,
    path: RoutesPaths.PAGE_NOT_FOUND,
    title: '404',
  },
  {
    component: ShippingKitInfo,
    path: RoutesPaths.KIT_SHIPPING_BY_ID,
    title: 'Shipping kits',
  },
];

export const navbarRoutes: NavBarRouteType[] = [
  {
    path: RoutesPaths.HOME,
    title: 'Home',
  },
  {
    path: RoutesPaths.ABOUT,
    title: 'About & FAQs',
  },
  {
    path: RoutesPaths.FAQS_SPANISH,
    title: 'Acerca de y preguntas frecuentes',
  },
  {
    path: RoutesPaths.FOR_FAMILIES,
    title: 'For Families',
  },
  {
    path: RoutesPaths.FOR_FAMILIES_SPANISH,
    title: 'Para Familias',
  },
  {
    path: `/${RoutesPaths.WORKSHOPS}`,
    title: 'Find Workshops',
  },
  {
    path: RoutesPaths.CHILD_CARE,
    title: 'Child Care & Preschool Providers',
  },
];

export const footerRoutes: NavBarRouteType[] = [
  {
    path: RoutesPaths.IDAHO_ABOUT,
    title: 'ABOUT IDAEYC',
  },
  {
    path: RoutesPaths.IDAHO_FAMILY_RESOURCES,
    title: 'FAMILY RESOURCES',
  },
  {
    path: RoutesPaths.IDAHO_JOB_BOARD,
    title: 'JOB BOARD',
  },
  {
    path: RoutesPaths.IDAHO_ECE_RESOURCES,
    title: 'ECE RESOURCES',
  },
  {
    path: RoutesPaths.IDAHO_GET_INVOLVED,
    title: 'GET INVOLVED',
  },
];

export const outsideAppLayoutRoutes: RouteType[] = [
  {
    component: MeetingLink,
    path: RoutesPaths.MEETING_LINK,
    title: 'Meeting link',
  },
];
