import { FC } from 'react';

export type NavBarRouteType = {
  path: string;
  title?: string;
};

export type RouteType = {
  component: FC<unknown>;
  path: string;
  title?: string;
};

export enum RoutesPaths {
  WORKSHOPS = 'workshops',
  WORKSHOP = 'workshop',
  WORKSHOPS_BY_ID = 'workshop/:id',
  KIT_SHIPPING_BY_ID = 'kit/shipping',
  MEETING_LINK = 'meeting-link',
  HOME = `https://readyforkindergartenidaho.org/home`,
  ABOUT = `https://readyforkindergartenidaho.org/about`,
  FOR_FAMILIES = `https://readyforkindergartenidaho.org/for-families`,
  IDAHO_AEYC = `https://www.idahoaeyc.org/`,
  CONTACT = `https://readyforkindergartenidaho.org/contact`,
  IDAHO_ABOUT = `https://www.idahoaeyc.org/about`,
  IDAHO_FAMILY_RESOURCES = `https://www.idahoaeyc.org/family-resources`,
  IDAHO_JOB_BOARD = `https://www.idahoaeyc.org/job-board`,
  IDAHO_ECE_RESOURCES = `https://www.idahoaeyc.org/ece-resources`,
  IDAHO_GET_INVOLVED = `https://www.idahoaeyc.org/get-involved`,
  CHILD_CARE = 'https://readyforkindergartenidaho.org/ccp-page',
  FAQS_SPANISH = 'https://readyforkindergartenidaho.org/about-1',
  FOR_FAMILIES_SPANISH = 'https://readyforkindergartenidaho.org/for-families-1',
  PAGE_NOT_FOUND = '404',
}
