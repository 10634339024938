// remove when  tables for demo are created

import { EI18nLanguageUI, EWorkshopChildcareAgeGroups } from '@packages/utils';
import {
  EI18nLanguageDB,
  ELanguage,
  TAddressTypesDB,
  TAddressTypesUI,
  TOption,
  TYesNoOptionType,
} from './generic.types';

export enum EWorkshopType {
  READY_FOR_KINDERGARTEN = 'READY_FOR_KINDERGARTEN',
  READY_FOR_MATH = 'READY_FOR_MATH',
  READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL = 'READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL',
  LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS = 'LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS',
  CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1 = 'CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1',
  CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2 = 'CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2',
}

export enum EWorkshopAgeGroup {
  ZERO_TO_ONE = 'ZERO_TO_ONE',
  ONE_TO_TWO = 'ONE_TO_TWO',
  TWO_TO_THREE = 'TWO_TO_THREE',
  TWO_TO_FIVE = 'TWO_TO_FIVE',
  THREE_TO_FOUR = 'THREE_TO_FOUR',
  THREE_TO_FIVE = 'THREE_TO_FIVE',
  FOUR_TO_FIVE = 'FOUR_TO_FIVE',
}

export enum EWorkshopCategory {
  STANDALONE = 'STANDALONE',
  SERIES_WITH_THREE_PARTS = 'SERIES_WITH_THREE_PARTS',
  SERIES_WITH_TWO_PARTS = 'SERIES_WITH_TWO_PARTS',
}

export enum EWorkshopPartType {
  PART_ONE = 'PART_ONE',
  PART_TWO = 'PART_TWO',
  PART_THREE = 'PART_THREE',
  STANDALONE = 'STANDALONE',
}

export enum EWorkshopsFilter {
  CHILD_CARE_PROVIDED = 'CHILD_CARE_PROVIDED',
  MEAL_PROVIDED = 'MEAL_PROVIDED',
}

export enum ERegistrantStatus {
  ACTIVE = 'ACTIVE',
  WAIT_LIST = 'WAIT_LIST',
}

export type TChildnameFormListItem = {
  name: string;
};

export type TWorkshopChildcareRequirementsUI = {
  name: EWorkshopChildcareAgeGroups;
  checked: boolean;
  value: number;
};

export type TWorkshopChildcareRequirementsDB = {
  ageGroup: EWorkshopChildcareAgeGroups;
  childCount: number;
};

export type TWorkshopRegisterDataDB = {
  workshopId: string;
  workshopClassId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  childNames: string[];
  childcareRequirements: TWorkshopChildcareRequirementsDB[];
  recaptchaResponse: string;
  hasReadyBinder?: boolean | null;
  userLanguage: EI18nLanguageDB;
  materialsLanguage?: ELanguage;
  customQuestionAnswer?: string;
  allowedTakePicture?: boolean | null;
  acceptedTerms: boolean;
  additionalAdults?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }[];
};

export type TWorkshopRegisterDataUI = {
  workshopId: string;
  workshopClassId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  childNames: TChildnameFormListItem[];
  childcareRequirements?: TWorkshopChildcareRequirementsUI[];
  recaptchaResponse: string;
  hasReadyBinder: TYesNoOptionType;
  userLanguage: EI18nLanguageUI;
  materialsLanguage?: ELanguage;
  customQuestionAnswer?: string;
  allowedTakePicture: TYesNoOptionType;
  acceptedTerms: boolean;
  additionalAdults?: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }[];
};

export enum EAttendanceType {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL',
}

export type TSessionsDB = {
  dateTime: string;
  isPassed: boolean;
  partType: EWorkshopPartType;
};

export type TSessionsUi = {
  dateTime: string | null;
  isPassed: boolean;
  partType: EWorkshopPartType;
};

export type TClassesDB = {
  ageGroup: EWorkshopAgeGroup;
  language: ELanguage;
  sessions: TSessionsDB[];
  workshopClassId: string;
};

export type TClassesUi = {
  ageGroup: EWorkshopAgeGroup;
  language: ELanguage;
  sessions: TSessionsUi[];
  workshopClassId: string;
};

export type TLanguageStatusItemUi = {
  sessions: TSessionsUi[];
  language: ELanguage;
};

export type TLanguagesStatusUi = {
  language: ELanguage;
  isPassed: boolean;
};

export type TPartInfoDB = {
  partType: EWorkshopPartType;
  parts: TSessionsDB[];
};

export type TPartInfoUi = {
  partType: EWorkshopPartType;
  isPassed: boolean;
  dateTime: string | null;
};

export type TWorkshopEventsDB = {
  classes: TClassesDB[];
  childcareProvided: boolean;
  creationDate: string;
  foodProvided: boolean;
  locationAddress: string;
  locationName: string;
  workshopCategory: EWorkshopCategory;
  workshopId: string;
  workshopPhoto: string;
  workshopType: EWorkshopType;
  attendanceType: EAttendanceType;
};

export type TWorkshopEventsUI = {
  classes: TClassesUi[];
  childcareProvided: boolean;
  creationDate: string;
  foodProvided: boolean;
  locationAddress: string;
  locationName: string;
  workshopCategory: EWorkshopCategory;
  workshopId: string;
  workshopPhoto: string;
  workshopType: EWorkshopType;
  ageGroups: string[];
  languagesStatus: TLanguagesStatusUi[];
  sessionsGrouped: TPartInfoUi[];
  attendanceType: EAttendanceType;
};

export type TWorkshopDB = {
  hostName: string | null;
  workshopEvents: TWorkshopEventsDB[];
};

export type TWorkshopUI = {
  hostName: string | null;
  workshopEvents: TWorkshopEventsUI[];
};

export type TPartsDB = {
  partType: EWorkshopPartType;
  dateTime: string;
  isPassed: boolean;
};

export type TPartsUI = {
  partType: EWorkshopPartType;
  date: { date: string; time: string };
  dateTimeString: string | null;
  isPassed: boolean;
};

export type TClassDB = {
  workshopClassId: string;
  ageGroup: EWorkshopAgeGroup;
  language: ELanguage;
  sessions: TPartsDB[];
};

export type TClassUI = {
  classId: string;
  date: string;
  ageGroup: EWorkshopAgeGroup;
  language: ELanguage;
  sessions: TPartsUI[];
  isAllSessionsPassed: boolean;
};

export type TClassesByAgeGroupUI = [EWorkshopAgeGroup, TClassUI];

export type TClassesByAgeGroupDB = {
  [key in EWorkshopAgeGroup]: TClassDB;
};

export type TAgeGroupOptionsByLanguageUI = {
  [key in ELanguage]: TOption[];
};

export type TClassesGroupedByAgeUI = {
  ageGroup?: EWorkshopAgeGroup;
  classes?: TClassesUi[];
};

export type TLocationCoordinatesDB = {
  latitude: number;
  longitude: number;
};

export type TLocationCoordinatesUI = {
  latitude: number;
  longitude: number;
};

export type TWorkshopDetailDB = {
  childcareProvided: boolean;
  classes: TClassesDB[];
  creationDate: string;
  foodProvided: boolean;
  hostId: string;
  hostName: string;
  locationAddress: string;
  locationCoordinates: TLocationCoordinatesDB;
  locationAddressId: string;
  locationName: string;
  workshopCategory: EWorkshopCategory;
  workshopDescription: string;
  workshopId: string;
  workshopPhoto: string;
  workshopType: EWorkshopType;
  attendanceType: EAttendanceType;
};

export type TWorkshopDetailUI = {
  childcareProvided: boolean;
  classes: TClassesGroupedByAgeUI[];
  creationDate: string;
  foodProvided: boolean;
  hostId: string;
  hostName: string;
  locationAddress: string;
  locationCoordinates: TLocationCoordinatesUI;
  locationAddressId: string;
  locationName: string;
  workshopCategory: EWorkshopCategory;
  workshopDescription: string;
  workshopId: string;
  workshopPhoto: string;
  workshopType: EWorkshopType;
  isAllClassesFinished: boolean;
  attendanceType: EAttendanceType;
};

export type TKitShippingAddressEditUI = {
  parentOrderId: string | null;
  address: TAddressTypesUI;
};

export type TKitShippingAddressEditDB = {
  parentOrderId: string | null;
  address: TAddressTypesDB;
};

export type TSubscribeDataUI = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cities: string[];
  ageGroups: EWorkshopAgeGroup[];
  languages: ELanguage[];
  recaptchaResponse: string;
};

export type TSubscribeDataDB = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cities: string[];
  ageGroups: EWorkshopAgeGroup[];
  languages: ELanguage[];
  recaptchaResponse: string;
};

export type TWorkshopRegisterResponseUI = {
  status: ERegistrantStatus;
  registrantId: string;
};
export type TWorkshopRegisterResponseDB = {
  status: ERegistrantStatus;
  registrantId: string;
};

export type TRegistrationFormDataDB = {
  childcareProvided: boolean;
  foodProvided?: boolean;
  customQuestion?: string;
  englishClasses: TClassesByAgeGroupDB;
  hostId: string;
  hostName: string;
  spanishClasses: TClassesByAgeGroupDB;
  parts: TPartsDB[];
  workshopCategory: EWorkshopCategory;
  workshopId: string;
  workshopType: EWorkshopType;
  locationAddress?: string;
};

export type TRegistrationFormDataUI = {
  childcareProvided: boolean;
  foodProvided?: boolean;
  hostId: string;
  hostName: string;
  workshopCategory: EWorkshopCategory;
  workshopId: string;
  workshopType: EWorkshopType;
  englishClasses: TClassesByAgeGroupUI[];
  spanishClasses: TClassesByAgeGroupUI[];
  languageOptions: TOption[];
  parts: TPartsUI[];
  ageGroupOptions: TAgeGroupOptionsByLanguageUI;
  customQuestion?: string;
  isCCP: boolean;
  locationAddress?: string;
};

export type TLightBoxUI = {
  id: string;
  firstSection: string;
  secondSection: string;
  picture: string;
  published: boolean;
};

export type TLightBoxDB = {
  id: string;
  firstSection: string;
  secondSection: string;
  picture: string;
  published: boolean;
};

export type TWorkshopMultipleRegisterDataUI = {
  registrationDetails: TWorkshopRegisterDataUI[];
};

export type TWorkshopMultipleRegisterDataDB = {
  registrationDetails: TWorkshopRegisterDataDB[];
};

export type TWorkshopMultipleRegisterResponseUI = TWorkshopRegisterResponseUI[];

export type TWorkshopMultipleRegisterResponseDB = TWorkshopRegisterResponseDB[];
